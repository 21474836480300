import { ApplicationType } from "libs/interfaces/src/lib/enums/applicationType.enum";

export class ApplicationTypeUtility {
    public static applicationTypes: Array<{ name: string; value: string }> = null;

    public static getApplicationTypes(): string[] {
        return Object.keys(ApplicationType);
    }

    private static getAllApplicationTypes() {
        if(ApplicationTypeUtility.applicationTypes !== null) {
            return ApplicationTypeUtility.applicationTypes;
        }

        ApplicationTypeUtility.applicationTypes = Object.keys(ApplicationType).map((key) => {
            return { name: key, value: ApplicationType[key] };
        });
    }

    public static getApplicationTypeByValue(value: string): string {
        ApplicationTypeUtility.getAllApplicationTypes();
        return ApplicationTypeUtility.applicationTypes.find((type) => type.value === value).name;
    }
}